// extracted by mini-css-extract-plugin
export var wrapper = "Sellers-module--wrapper--+q4yn";
export var slideshow_container = "Sellers-module--slideshow_container--gd9iu";
export var title = "Sellers-module--title--RrHyg";
export var subtitle = "Sellers-module--subtitle--NU9uK";
export var desc = "Sellers-module--desc--viKcL";
export var section_one = "Sellers-module--section_one--CdkVi";
export var section_one__one = "Sellers-module--section_one__one--ddrzn";
export var section_one__two = "Sellers-module--section_one__two--vRox6";
export var desc__one = "Sellers-module--desc__one--OoQy8";
export var desc__two = "Sellers-module--desc__two--ryRnV";
export var desc__three = "Sellers-module--desc__three--QWkrs";
export var section_two = "Sellers-module--section_two--dbb5o";
export var section_two__two = "Sellers-module--section_two__two--Lv0h1";
export var section_two__one = "Sellers-module--section_two__one--ynlk0";
export var btn__wrapper = "Sellers-module--btn__wrapper--MI5qO";
export var login = "Sellers-module--login--s4S+5";
export var desc__four = "Sellers-module--desc__four--zn6BA";
export var section_three = "Sellers-module--section_three--uUM2B";
export var feature_wrapper = "Sellers-module--feature_wrapper--dgVIp";
export var feature_img_block = "Sellers-module--feature_img_block--nRy6P";
export var img_wrapper = "Sellers-module--img_wrapper--HZaSa";