import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import SearchHelper from "@components/AC-Header/SearchHelper";
import * as classes from "./Sellers.module.css";
import { navigate } from "@reach/router";

// import { useStaticQuery, graphql } from "gatsby";
// import { Link } from "gatsby";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";
// import Img from "gatsby-image";


// import { I18nContext } from "@/i18n/index";
// import sdfsdf from "../header/SearchHelper";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const SellersSlider = () => {

  // const { langCode } = React.useContext(I18nContext);

  // const navCatsState = useSelector(
  //   state => state.menuReducer.navCats,
  //   shallowEqual
  // );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const handleLogin = () => {
    document.getElementById("login-icon-btn").click();
  };

  // const handleSignup = () => {
  //   navigate("https://app.dibizglobal.com/signup")
  // }

  return (
    <>
      <div id="homeBanner">
        <div className="homeBanner-title">
          Grow your business faster.
        </div>
        <div className={classes.subtitle}>Scale your market reach with your certified sustainable products</div>
        <div className="homeBanner-desc">
          DIBIZ is built for suppliers who want more visibility of their sustainable products and get the right price.
        </div>
        {
          loginNameState === "" && (
            <div className={classes.section_two__one}>
              <div className={classes.btn__wrapper}>
                <button >
                  <a href="https://app.dibizglobal.com/signup">Register with DIBIZ</a>
                </button>
                <div className={classes.login}>
                  Existing Customer?
                  <span onClick={handleLogin}>Login from here</span>
                </div>
              </div>
            </div>
          )
        }

      </div>

      {/*<AutoPlaySwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={index}>
            <div className="sliderBtns">
              <p>Your  Curbside Pickup  Marketplace</p>
              <Link to={PREVIEW + "/farmers-markets"}>
                Farmer's Markets
              </Link>
              <Link to={PREVIEW + "/shop/retail-store"}>Retail Stores</Link>
              <Link to={PREVIEW + "/shop/restaurants"}>Restaurants</Link>
              <Link to={PREVIEW + "/shop/services"}>Services</Link>
            </div>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                className="BannerDeskMobile"
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton=""
        backButton=""
        style={{
          transform: "translateY(-30px)",
          position: "absolute",
          background: "transparent",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          width: "100%"
        }}
      />*/}
    </>
  );
};

export default SellersSlider;
