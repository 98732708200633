import React from "react";
import { useSelector, shallowEqual } from "react-redux";
// import SearchHelper from "../AC-Header/SearchHelper";
import { useStaticQuery, graphql } from "gatsby";
import * as classes from "./Sellers.module.css";
import { navigate } from "@reach/router";

const SellersSlider_Mobile = () => {

  // const data = useStaticQuery(graphql`
  //   query {
  //     img2: file(relativePath: { eq: "slider/slider2_en.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1600) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //   }
  // `);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const handleLogin = () => {
    document.getElementById("login-icon-btn").click();
  };

  const handleSignup = () => {
    navigate("https://app.dibizglobal.com/signup")
  }

  return (
    <>
      <div id="homeBanner__mobile">
        <div className="homeBanner-title__mobile">
          Grow your business faster.
        </div>
        <div className="homeBanner-desc__mobile">
          DIBIZ is built for suppliers who want more visibility of their sustainable products and get the right price.
        </div>
        {
          loginNameState === "" && (
            <div className={classes.section_two__one}>
              <div className={classes.btn__wrapper}>
                <button onClick={handleSignup}>Register with DIBIZ</button>
                <div className={classes.login}>
                  Existing Customer?
                  <span onClick={handleLogin}>Login from here</span>
                </div>
              </div>
            </div>
          )
        }
        {/* <SearchHelper />  */}
      </div>
    </>
  );
};

export default SellersSlider_Mobile;
