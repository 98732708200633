import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SellersSlider from "./SellersSlider"
import SellersSliderMobile from "./SellersSlider_Mobile";
import { navigate } from "@reach/router";

import * as classes from "./Sellers.module.css";


const Sellers = () => {


    const navCatsState = useSelector(
        state => state.menuReducer.navCats,
        shallowEqual
    );

    const isMobileState = useSelector(
        state => state.mainReducer.isMobile,
        shallowEqual
    );


    return (
        <>
            <div className={classes.slideshow_container}>
                {
                    isMobileState ? <SellersSliderMobile /> : <SellersSlider />
                }
            </div>
            <div className={classes.wrapper}>
                {/* <h3 className={classes.title}><span>Equal benefits</span> for Buyers and Suppliers</h3>
                <p className={classes.desc}>Are you finding it hard to find and source authentic certified and sustainable products? DIBIZ is the right place to start with. Here, you can find verified suppliers and buyers on a global scale and start interacting with them. Reach out to us for more information.</p> */}
                {/* <div className={classes.section_one}>
                    <div className={classes.section_one__one} >
                        <img src="https://ik.imagekit.io/ofb/dbz/illustration_home_6yHjxDDFB.png" alt="Image" />
                    </div>
                    <div className={classes.section_one__two}>
                        <h3>DIBIZ for <span> {" "} Buyers</span> </h3>
                        <div className={classes.desc__one}>Find and trade your products on one marketplace</div>
                        <p className={classes.desc__two}>Tired of searching for certified sustainable products that cannot give you a guaranteed traceability. DIBIZ offers a new way to search and identify suppliers and connect them with ideal buyers.</p>
                        <ul className={classes.desc__three}>
                            <li>Find best certified products and organizations </li>
                            <li>Brief understanding of the company before reaching out to them </li>
                            <li>Reach out to them, discuss, and negotiate </li>
                            <li>Trade through DIBIZ (optional)</li>
                            <li>Access to global and regional markets</li>
                        </ul>
                        <a>
                            <i className="material-icons-outlined">
                                east
                            </i>
                            Learn more
                        </a>
                        <button>Register with DIBIZ</button>
                    </div>
                </div> */}
                <div className={classes.section_two}>
                    <div className={classes.section_two__one}>
                        <h3>Now you have exposure to more buyers seeking authentic sustainable goods and in turn better premium for the produce.</h3>
                        {/* <div className={classes.desc__one}>Scale your market reach with your certified sustainable products</div>
                        <p className={classes.desc__two}>Discover new ways of digital distribution of your certified products. Sell more of your sustainable products for a premium to discerning customers. Receive request for quotes from verified buyers and start a new relationship.</p> */}

                        <h5 >List your certified products and receive the<span> best value</span> for your product</h5>
                        <div className={classes.desc__three}>Tired of dealing with unverified buyers with no credible information?. With DIBIZ you can now trade with trust!</div>
                        <ul className={classes.desc__four}>
                            <li>List unlimited products</li>
                            <li>Opportunity to sell on a global stage  </li>
                            <li>Expand your brand presence </li>
                            <li>Right value for your product </li>
                            <li>Brief understanding of the company before dealing with them </li>
                            <li>Ability to discuss and negotiate within the platform</li>
                        </ul>

                        {/* <div className={classes.btn__wrapper}>
                            <button>Register with DIBIZ</button>
                            <div className={classes.login}>
                                Existing Customer?
                                <a href="javascipt:void(0)">Login from here</a>
                            </div>
                        </div> */}
                        {/* <a>
                            <i className="material-icons-outlined">
                                east
                            </i>
                            Learn more
                        </a> */}

                    </div>
                    <div className={classes.section_two__two}>
                        <img src="https://ik.imagekit.io/ofb/dbz/Group_320_2x_z4AlPC1R0G.png" alt="Image" />
                    </div>
                </div>
                <div className={classes.section_three}>
                    <h3>Key Features</h3>
                    <div className={classes.feature_wrapper}>
                        <div className={classes.feature_img_block}>
                            <div className={classes.img_wrapper}>
                                <img src="https://ik.imagekit.io/ofb/dbz/management_2x_Gv6fey4YY.png" alt="Images" />
                            </div>
                            <h6>Trade Management</h6>
                            <p>Easily manage your trades and integrate with your trade management system</p>
                        </div>
                        <div className={classes.feature_img_block}>
                            <div className={classes.img_wrapper}>
                                <img src="https://ik.imagekit.io/ofb/dbz/integrated_2x_8ePjIawmWV.png" alt="Images" />
                            </div>
                            <h6>Fully integrated trade system and marketplace</h6>
                            <p>Integration to a standalone DIBIZ system that manages your entire trade history, status and inventory.</p>
                        </div>
                        <div className={classes.feature_img_block}>
                            <div className={classes.img_wrapper}>
                                <img src="https://ik.imagekit.io/ofb/dbz/messages_2x_s-4jzxqzhW.png" alt="Images" />
                            </div>
                            <h6>Quotes and messaging system</h6>
                            <p>Create, exchange quotes and messages using the quote generator and chat system.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sellers;
